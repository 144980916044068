<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            A viccként tálalt verbális bántalmazás
          </h2>
          <p class="hashText">– Nem mindegy, hogy rajtunk, vagy velünk együtt nevetnek</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="10" class="side-gap offset-lg-1">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                Valószínűleg sokunkkal előfordult már az a kellemetlen szituáció, amikor gúnyolódás céltáblájává váltunk: egy vicc elhangzásakor a többiek rajtunk nevettek, és mi nem tudtunk velük tartani, leblokkoltunk – például a munkahelyünkön, vagy egy baráti társaságban. </p>

                <p class="text">
                Ilyen esetekben a résztvevők általában két csoportra oszlanak: azokra, akik nevetnek és értik a viccet, illetve azokra, akikről maga a nevetés szól.  Az utóbbi csoportot így sokszor humortalanként definiálják, kiközösítik, hiszen „ez csak vicc volt, nem kell komolyan venni”. </p>

                <p class="text">
                A viccelődés, gúnyolódás ugyanakkor fájdalmas is lehet annak, aki megéli. Nincs fizikai jele, ráadásul könnyen megkérdőjelezhetőek a középpontba került személy érzelmei is, éppen ezért nehezebben felismerhető – ugyanakkor fontos, hogy tudjuk, a fenyegetés és ítélkezés mellett a viccnek álcázott sérelmezés is a verbális bántalmazás egyik formája. Célja a kontroll fenntartása a másik fél (bántalmazott) felett, illetve gyakori a felsőbbrendűség megtartása, kimutatása is.</p>
                <p class="text">
                Okos eszközeink az online térben is könnyen teret adhatnak a verbális bántalmazásnak: csupán elég egy nyitva felejtett telefonzárolás, egy ellesett kód, és máris bárki nyugodtan küldhet viccesnek szánt, ugyanakkor kellemetlen és sok esetben hamis üzeneteket az ismerőseinknek, adott esetben a főnökünknek, állíthat be illetlen háttérképet a munkahelyi laptopunkon, vagy tölthet fel rólunk egy-egy előnytelen képet a közösségi oldalainkra. </p>
                <p class="text">
                Éppen ezért fontos, hogy tudatosan használjuk okostelefonjainkat, vigyázzunk jelszavainkra, kódjainkra, így megelőzhetjük, hogy kellemetlen szituációk középpontjába kerüljünk. Ne feledjük: a humorizálás nem egyértelműen pozitív, a szarkasztikus vagy a fekete humor kifejezetten negatívnak minősül. A vicc csak akkor vicces, ha mindannyian szívből tudunk nevetni rajta.</p>
            </div>
            </b-col>
            <b-col cols="12" class="order-4 order-lg-1">
              <img src="images/vicc-bantalmazas.png" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "A viccként tálalt verbális bántalmazás", 
        desc: "Nem mindegy, hogy rajtunk, vagy velünk együtt nevetnek", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "A viccként tálalt verbális bántalmazás",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Nem mindegy, hogy rajtunk, vagy velünk együtt nevetnek"        
    })
  }
}
</script>